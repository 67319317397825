<template>
    <router-view />
</template>

<script>
export default {
    data() {
        return {
            timeoutId:'',
            timeoutInMS : 1800000 // 3 minutes -> 3 * 60 * 1000=180000
        };
    },
    created() {
        this.setHostUri();
        this.checkTokenAvailability();
        this.setupTimers();
    },
    mounted() {
        this.setupTimers();
    },
    methods: {
        checkTokenAvailability() {
            let sessionData = JSON.parse(localStorage.getItem("frontlogInput"));
            if (sessionData) {
                this.$store.commit("UPDATE_TOKEN_FROM_LOGIN", sessionData.token);
            } else {
                this.$router.push("/").catch(() => { });
            }
        },
        logout() {
            localStorage.removeItem("frontlogInput");
            this.$store.commit("UPDATE_TOKEN_FROM_LOGIN", null);
            this.isAuthenticated = false;
            this.$router.push("/").catch(() => { });
        },
        setHostUri() {
            this.$store.commit("SET_HOST_NAME", localStorage.getItem('app_url'));
        },
        handleInactive() {
            // Here you want to logout a user and/or ping your token
             let sessionData = JSON.parse(localStorage.getItem("frontlogInput"));
            if (sessionData) {
                localStorage.removeItem("frontlogInput");
                this.$store.commit("UPDATE_TOKEN_FROM_LOGIN", null);
                this.$router.push("/").catch(() => { });
            }
        },

        startTimer() {
            //console.log(this.timeoutInMS);
            // setTimeout returns an ID (can be used to start or clear a timer)
            this.timeoutId = setTimeout(this.handleInactive, this.timeoutInMS);
        },

        resetTimer() {
            clearTimeout(this.timeoutId);
            this.startTimer();
        },
        setupTimers() {
            document.addEventListener("keypress", this.resetTimer, false);
            document.addEventListener("mousemove", this.resetTimer, false);
            document.addEventListener("mousedown", this.resetTimer, false);
            document.addEventListener("touchmove", this.resetTimer, false);
            this.startTimer();
        }
    },
    mounted() { },
};
</script>
