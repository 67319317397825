import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueToast from 'vue-toast-notification';
import Vuex from 'vuex';
Vue.use(VueAxios, axios);
Vue.use(VueToast);
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      token: null,
      isLogin: false,
      siteCurrency: "৳",
      hostBase: null,      
      companyInfo:{},
      companyImg:null,
      leftSidebarVal: false,
      rightSidebarVal: false,
    },
    getters: {
        
        
        companyinfo(state){          
          return state.companyInfo;
        },
        companyImg(state){          
          return state.companyImg;
        },
    },
    actions: {
  
    
      getCompanyInfos({ commit, state }){        
        var qs = require("qs");
        var data = qs.stringify({});
        var config = {
          method: "post",
          url: state.hostBase + "/api/company_param",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data,
        };
        axios(config)
          .then((response) => {
            commit('SET_COMPANY_ITEMS', response.data.data[0]);
            commit('SET_COMPANY_IMG', response.data.url);
          })
          .catch((error) => {
            console.dir(error)
          });
      },
    }, 
    mutations: {
    
      SET_COMPANY_ITEMS(state, items){
        state.companyInfo = items;
      },
      SET_COMPANY_IMG(state, items){
        state.companyImg = items;
      },
      SET_HOST_NAME(state, uri){
        state.hostBase = uri;
      },
      UPDATE_TOKEN_FROM_LOGIN(state, loginToken) {
          state.token = loginToken;
      },
      UPDATE_ISLOGIN(state, value) {
          state.isLogin = value;
      },
      CHANGE_LEFT_SIDEBAR(state, value){
        state.leftSidebarVal = value;
      },
      CHANGE_RIGHT_SIDEBAR(state, value){
        state.rightSidebarVal = value;
      }
    },
})