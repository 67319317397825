
const routes = [    
    { path: '', name: '', component: () => import(/* webpackChunkName: "master" */ './pages/Master') },
    { path: '/about', name: 'about', component: () => import(/* webpackChunkName: "about" */ './pages/About') },
    { path: '/contact', name: 'contact', component: () => import(/* webpackChunkName: "contact" */ './pages/Contact') },

    { path: '/feedback', name: 'feedback', component: () => import(/* webpackChunkName: "feedback" */ './pages/Feedback') },
    
    { path: '/faq', name: 'faq', component: () => import(/* webpackChunkName: "faq" */ './pages/FAQ') },
    { path: '/register', name: 'register', component: () => import(/* webpackChunkName: "register" */ './pages/Register') },
    { path: '/login', name: 'login', component: () => import(/* webpackChunkName: "login" */ './pages/Login') },
    { path: '/clearance', name: 'clearance', component: () => import(/* webpackChunkName: "clearance" */ './pages/Clearance') },
    { path: '/e-payment', name: 'e-payment', component: () => import(/* webpackChunkName: "e-payment" */ './pages/Epayment') },
    { path: '/booking', name: 'booking', component: () => import(/* webpackChunkName: "booking" */ './pages/Booking') },

    { path: '/document-withdraw', name: 'document-withdraw', component: () => import(/* webpackChunkName: "document-withdraw" */ './pages/DocumentWithdraw') },

    { path: '/home', name: 'home', component: () => import(/* webpackChunkName: "home" */ './dashboardPages/Home') },
    { path: '/clearance-apply', name: 'clearance-apply', component: () => import(/* webpackChunkName: "clearance-apply" */ './dashboardPages/ClearanceApply') },
    { path: '/clearance-report', name: 'clearance-report', component: () => import(/* webpackChunkName: "clearance-report" */ './dashboardPages/ClearanceReport') },

    { path: '/clearance-report-details', name: 'clearance-report-details', component: () => import(/* webpackChunkName: "clearance-report-details" */ './dashboardPages/ClearanceReportDetails') },

    { path: '/booking-report-details', name: 'booking-report-details', component: () => import(/* webpackChunkName: "booking-report-details" */ './dashboardPages/BookingReportDetails') },

    { path: '/document-report-details', name: 'document-report-details', component: () => import(/* webpackChunkName: "document-report-details" */ './dashboardPages/DocumentReportDetails') },

    { path: '/document-apply-student', name: 'document-apply-student', component: () => import(/* webpackChunkName: "document-apply" */ './dashboardPages/DocumentApplyStudent') },
    { path: '/document-apply-employee', name: 'document-apply-employee', component: () => import(/* webpackChunkName: "document-apply" */ './dashboardPages/DocumentApplyEmployee') },
    { path: '/document-report', name: 'document-report', component: () => import(/* webpackChunkName: "document-report" */ './dashboardPages/DocumentReport') },
    { path: '/pay-payment', name: 'pay-payment', component: () => import(/* webpackChunkName: "document-report" */ './dashboardPages/EpaymentPay') },

    { path: '/booking-apply', name: 'booking-apply', component: () => import(/* webpackChunkName: "booking-apply" */ './dashboardPages/BookingApply') },
    { path: '/booking-report', name: 'booking-report', component: () => import(/* webpackChunkName: "booking-report" */ './dashboardPages/BookingReport') },
    { path: '/booking-calendar', name: 'booking-calendar', component: () => import(/* webpackChunkName: "booking-calendar" */ './dashboardPages/BookingCalendar') },
    
    { path: '/epayment-pay', name: 'epayment-pay', component: () => import(/* webpackChunkName: "epayment-pay" */ './dashboardPages/EpaymentPay') },
    { path: '/epayment-report', name: 'epayment-report', component: () => import(/* webpackChunkName: "epayment-report" */ './dashboardPages/EpaymentReport') },
    
    { path: '/fee/credit-fee', name: 'credit-fee', component: () => import(/* webpackChunkName: "credit-fee" */ './dashboardPages/CreditFee') },
    { path: '/fee/registration-fee', name: 'registration-fee', component: () => import(/* webpackChunkName: "registration-fee" */ './dashboardPages/RegFee') },

    { path: '/payment/go/:paymentid/:methodid', name: 'paymentgo', component: () => import(/* webpackChunkName: "paymentgo" */ './dashboardPages/PaymentInfo') },
    { path: '/clearance/payment/:key', name: 'clearancepayment', component: () => import(/* webpackChunkName: "clearancepayment" */ './dashboardPages/ClearanceFee') },

    { path: '/document/payment/:key', name: 'documentpayment', component: () => import(/* webpackChunkName: "documentpayment" */ './dashboardPages/DocumentFee') },
]; 

export default routes;