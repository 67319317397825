window.Vue = require('vue').default;
import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import ToggleButton from 'vue-js-toggle-button';
import VueLoaders from 'vue-loaders';
import 'vue-loaders/dist/vue-loaders.css';
import VueRouter from 'vue-router';
import VueSidebarMenu from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import tinymce from 'vue-tinymce-editor';
import { VuejsDatatableFactory } from 'vuejs-datatable';
import App from './App.vue';
import frontMixins from './mixins';
import routes from './routes';
import store from './store';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Select2 from 'v-select2-component';
import Multiselect from 'vue-multiselect';

import VueCryptojs from 'vue-cryptojs'

//Vue.use(VueCryptojs)

Vue.use(VueSidebarMenu)
Vue.use(VueToast);
Vue.use(ToggleButton);
Vue.use( VuejsDatatableFactory );
Vue.use(VueLoaders);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.component('tinymce', tinymce);
Vue.component('Select2', Select2);
Vue.component('Multiselect', Multiselect);
const router = new VueRouter({
    mode: 'history',
    routes: routes,
    linkExactActiveClass: "active"
});
const app = new Vue({
    el: '#wrapper',
    data: { },
    router: router,
    mixins: [frontMixins],
    store,
    render: h => h(App),
});


